import { Modal } from 'antd'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

const { confirm } = Modal

interface UseUrlUnsavedChangesGuardProps {
  hasUnsavedChanges: boolean
}

export const useUrlUnsavedChangesGuard = ({
  hasUnsavedChanges,
}: UseUrlUnsavedChangesGuardProps) => {
  const navigate = useNavigate()
  const { updateParam } = useUrlParams()
  const title = 'Unsaved changes'
  const content = 'Are you sure you want to leave? Your changes will be lost.'

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
  }, [])

  const showUnsavedChangesModal = useCallback((onOk: () => void) => {
    confirm({
      centered: true,
      icon: null,
      title,
      content,
      okText: 'Yes',
      onOk: () => onOk(),
    })
  }, [])

  const handleNavigationAttempt = useCallback(
    (event: CustomEvent<{ newSection: string }>) => {
      if (hasUnsavedChanges) {
        event.preventDefault()
        showUnsavedChangesModal(() => {
          if (event.detail?.newSection) {
            updateParam(UrlParam.SETTING_SECTION, event.detail.newSection)
          }
        })
      }
    },
    [hasUnsavedChanges, showUnsavedChangesModal, updateParam],
  )

  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault()
        event.returnValue = ''
        return ''
      }
      return undefined
    }

    const handlePopState = (event: PopStateEvent) => {
      const targetUrl = document.referrer
      if (hasUnsavedChanges) {
        event.preventDefault()
        const currentUrl = window.location.href
        window.history.pushState(null, '', currentUrl)

        showUnsavedChangesModal(() => {
          navigate(targetUrl)
        })
      }
    }

    const handleLinkClick = (event: MouseEvent) => {
      const link = (event.target as HTMLElement).closest('a')
      if (!link || !hasUnsavedChanges) return

      const href = link.getAttribute('href')
      if (!href || href.startsWith('#')) return

      event.preventDefault()
      event.stopPropagation()

      showUnsavedChangesModal(() => {
        navigate(href)
      })
    }

    window.addEventListener('popstate', handlePopState)
    document.addEventListener('click', handleLinkClick, true)
    window.addEventListener('beforeunload', beforeUnloadHandler)
    document.addEventListener('checkUnsavedChanges', handleNavigationAttempt as EventListener)

    return () => {
      window.removeEventListener('popstate', handlePopState)
      document.removeEventListener('click', handleLinkClick, true)
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      document.removeEventListener('checkUnsavedChanges', handleNavigationAttempt as EventListener)
    }
  }, [hasUnsavedChanges, showUnsavedChangesModal, navigate, handleNavigationAttempt])
}
