import { datadogRum } from '@datadog/browser-rum'
import { useFeatureFlagEnabled as useFFEnabled } from 'posthog-js/react'

export type FeatureFlagKey =
  | 'inventory-total-third-parties-change'
  | 'third-party-show-even-if-analyzing'
  | 'finding-show-even-if-invisible'
  | 'questionnaire-module'
  | 'third-party-subprocessors'
  | 'third-party-questionnaire-markdown'
  | 'logicmanager-integration'
  | 'wiz-integration'
  | 'notifications-email-preferences'
  | 'sso-settings'
  | 'third-party-usage-list-users'
  | 'dashboard'
  | 'dashboard-findings-over-time-chart'
  | 'assessment-conditionally-approved'
  | 'assessment-overview-show-prev-version-alert'
  | 'suggested-subprocessor-badge'
  | 'certificate-control-configuration'
  | 'questionnaire-module-v2'
  | 'irq-clear-all-button'
  | 'control-frameworks'

export const useFeatureFlagEnabled = (featureFlag: FeatureFlagKey) => {
  const isEnabled = useFFEnabled(featureFlag)
  datadogRum.addFeatureFlagEvaluation(featureFlag, isEnabled)
  if (isEnabled === undefined) {
    return { isLoading: true, isEnabled: false }
  } else {
    return { isLoading: false, isEnabled }
  }
}
