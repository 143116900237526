// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file questionnaire/v1/questionnaire_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetParticipantsRequest, GetParticipantsResponse, GetUploadLinkRequest, GetUploadLinkResponse, GetUserTokenRequest, GetUserTokenResponse, InviteRecipientRequest, InviteRecipientResponse, ListRecipientQuestionnairesRequest, ListRecipientQuestionnairesResponse, TransferArtifactToAssessorRequest, TransferArtifactToAssessorResponse } from "./questionnaire_service_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { QuestionnaireActivityRequest, QuestionnaireActivityResponse } from "./model_pb.js";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "questionnaire.v1.QuestionnaireService";

/**
 * @generated from service questionnaire.v1.QuestionnaireService
 */
export const QuestionnaireService = {
  typeName: "questionnaire.v1.QuestionnaireService",
  methods: {
    /**
     * the recipient authenticates so he can fill in the questionnaire
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetUserToken
     */
    getUserToken: {
      name: "GetUserToken",
      I: GetUserTokenRequest,
      O: GetUserTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.ListRecipientQuestionnaires
     */
    listRecipientQuestionnaires: {
      name: "ListRecipientQuestionnaires",
      I: ListRecipientQuestionnairesRequest,
      O: ListRecipientQuestionnairesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * the reciepent fills in the questionnaire
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetUploadLink
     */
    getUploadLink: {
      name: "GetUploadLink",
      I: GetUploadLinkRequest,
      O: GetUploadLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * recipients can invite others to fill the questionnaire with/for them
     *
     * @generated from rpc questionnaire.v1.QuestionnaireService.InviteRecipient
     */
    inviteRecipient: {
      name: "InviteRecipient",
      I: InviteRecipientRequest,
      O: InviteRecipientResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.GetParticipants
     */
    getParticipants: {
      name: "GetParticipants",
      I: GetParticipantsRequest,
      O: GetParticipantsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.TransferArtifactToAssessor
     */
    transferArtifactToAssessor: {
      name: "TransferArtifactToAssessor",
      I: TransferArtifactToAssessorRequest,
      O: TransferArtifactToAssessorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc questionnaire.v1.QuestionnaireService.QuestionnaireActivity
     */
    questionnaireActivity: {
      name: "QuestionnaireActivity",
      I: QuestionnaireActivityRequest,
      O: QuestionnaireActivityResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: QuestionnaireService,});

/**
 * the recipient authenticates so he can fill in the questionnaire
 *
 * @generated from rpc questionnaire.v1.QuestionnaireService.GetUserToken
 */
export const getUserToken: UnaryFunctionsWithHooks<GetUserTokenRequest, GetUserTokenResponse> = {   ...$queryService.getUserToken,  ...createUnaryHooks($queryService.getUserToken)};

/**
 * @generated from rpc questionnaire.v1.QuestionnaireService.ListRecipientQuestionnaires
 */
export const listRecipientQuestionnaires: UnaryFunctionsWithHooks<ListRecipientQuestionnairesRequest, ListRecipientQuestionnairesResponse> = {   ...$queryService.listRecipientQuestionnaires,  ...createUnaryHooks($queryService.listRecipientQuestionnaires)};

/**
 * the reciepent fills in the questionnaire
 *
 * @generated from rpc questionnaire.v1.QuestionnaireService.GetUploadLink
 */
export const getUploadLink: UnaryFunctionsWithHooks<GetUploadLinkRequest, GetUploadLinkResponse> = {   ...$queryService.getUploadLink,  ...createUnaryHooks($queryService.getUploadLink)};

/**
 * recipients can invite others to fill the questionnaire with/for them
 *
 * @generated from rpc questionnaire.v1.QuestionnaireService.InviteRecipient
 */
export const inviteRecipient: UnaryFunctionsWithHooks<InviteRecipientRequest, InviteRecipientResponse> = {   ...$queryService.inviteRecipient,  ...createUnaryHooks($queryService.inviteRecipient)};

/**
 * @generated from rpc questionnaire.v1.QuestionnaireService.GetParticipants
 */
export const getParticipants: UnaryFunctionsWithHooks<GetParticipantsRequest, GetParticipantsResponse> = {   ...$queryService.getParticipants,  ...createUnaryHooks($queryService.getParticipants)};

/**
 * @generated from rpc questionnaire.v1.QuestionnaireService.TransferArtifactToAssessor
 */
export const transferArtifactToAssessor: UnaryFunctionsWithHooks<TransferArtifactToAssessorRequest, TransferArtifactToAssessorResponse> = {   ...$queryService.transferArtifactToAssessor,  ...createUnaryHooks($queryService.transferArtifactToAssessor)};

/**
 * @generated from rpc questionnaire.v1.QuestionnaireService.QuestionnaireActivity
 */
export const questionnaireActivity: UnaryFunctionsWithHooks<QuestionnaireActivityRequest, QuestionnaireActivityResponse> = {   ...$queryService.questionnaireActivity,  ...createUnaryHooks($queryService.questionnaireActivity)};
