// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/control_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CompanySummary } from "./company_summary_pb.js";
import { Implication } from "./implication_pb.js";
import { Documentation } from "./documentation_pb.js";
import { Comment } from "./comment_pb.js";

/**
 * @generated from enum inventory.v1.ControlStatus
 */
export enum ControlStatus {
  /**
   * @generated from enum value: CONTROL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CONTROL_STATUS_VALIDATED = 1;
   */
  VALIDATED = 1,

  /**
   * @generated from enum value: CONTROL_STATUS_GAP = 2;
   */
  GAP = 2,

  /**
   * @generated from enum value: CONTROL_STATUS_NO_EVIDENCE = 3;
   */
  NO_EVIDENCE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ControlStatus)
proto3.util.setEnumType(ControlStatus, "inventory.v1.ControlStatus", [
  { no: 0, name: "CONTROL_STATUS_UNSPECIFIED" },
  { no: 1, name: "CONTROL_STATUS_VALIDATED" },
  { no: 2, name: "CONTROL_STATUS_GAP" },
  { no: 3, name: "CONTROL_STATUS_NO_EVIDENCE" },
]);

/**
 * @generated from enum inventory.v1.InherentRiskSetting
 */
export enum InherentRiskSetting {
  /**
   * @generated from enum value: INHERENT_RISK_SETTING_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_LOW_AND_ABOVE = 1;
   */
  LOW_AND_ABOVE = 1,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_MEDIUM_AND_ABOVE = 2;
   */
  MEDIUM_AND_ABOVE = 2,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_HIGH_AND_ABOVE = 3;
   */
  HIGH_AND_ABOVE = 3,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_CRITICAL_AND_ABOVE = 4;
   */
  CRITICAL_AND_ABOVE = 4,

  /**
   * @generated from enum value: INHERENT_RISK_SETTING_DISABLED = 5;
   */
  DISABLED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(InherentRiskSetting)
proto3.util.setEnumType(InherentRiskSetting, "inventory.v1.InherentRiskSetting", [
  { no: 0, name: "INHERENT_RISK_SETTING_UNSPECIFIED" },
  { no: 1, name: "INHERENT_RISK_SETTING_LOW_AND_ABOVE" },
  { no: 2, name: "INHERENT_RISK_SETTING_MEDIUM_AND_ABOVE" },
  { no: 3, name: "INHERENT_RISK_SETTING_HIGH_AND_ABOVE" },
  { no: 4, name: "INHERENT_RISK_SETTING_CRITICAL_AND_ABOVE" },
  { no: 5, name: "INHERENT_RISK_SETTING_DISABLED" },
]);

/**
 * @generated from message inventory.v1.AddCommentRequest
 */
export class AddCommentRequest extends Message<AddCommentRequest> {
  /**
   * @generated from field: optional string control_id = 1;
   */
  controlId?: string;

  /**
   * @generated from field: optional string finding_id = 2;
   */
  findingId?: string;

  /**
   * @generated from field: string body = 3;
   */
  body = "";

  constructor(data?: PartialMessage<AddCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "finding_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCommentRequest {
    return new AddCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddCommentRequest | PlainMessage<AddCommentRequest> | undefined, b: AddCommentRequest | PlainMessage<AddCommentRequest> | undefined): boolean {
    return proto3.util.equals(AddCommentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.AddCommentResponse
 */
export class AddCommentResponse extends Message<AddCommentResponse> {
  constructor(data?: PartialMessage<AddCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AddCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCommentResponse {
    return new AddCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddCommentResponse | PlainMessage<AddCommentResponse> | undefined, b: AddCommentResponse | PlainMessage<AddCommentResponse> | undefined): boolean {
    return proto3.util.equals(AddCommentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Control
 */
export class Control extends Message<Control> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: inventory.v1.ControlStatus status = 5;
   */
  status = ControlStatus.UNSPECIFIED;

  /**
   * @generated from field: bool is_enabled = 6;
   */
  isEnabled = false;

  /**
   * @generated from field: int32 order_priority = 7;
   */
  orderPriority = 0;

  /**
   * @generated from field: repeated string frameworks = 8;
   */
  frameworks: string[] = [];

  constructor(data?: PartialMessage<Control>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Control";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(ControlStatus) },
    { no: 6, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "order_priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "frameworks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Control {
    return new Control().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Control {
    return new Control().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Control {
    return new Control().fromJsonString(jsonString, options);
  }

  static equals(a: Control | PlainMessage<Control> | undefined, b: Control | PlainMessage<Control> | undefined): boolean {
    return proto3.util.equals(Control, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlSettingsResponse
 */
export class UpdateControlSettingsResponse extends Message<UpdateControlSettingsResponse> {
  constructor(data?: PartialMessage<UpdateControlSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSettingsResponse {
    return new UpdateControlSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSettingsResponse | PlainMessage<UpdateControlSettingsResponse> | undefined, b: UpdateControlSettingsResponse | PlainMessage<UpdateControlSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateControlSettingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlSettingsRequest
 */
export class UpdateControlSettingsRequest extends Message<UpdateControlSettingsRequest> {
  /**
   * @generated from field: repeated inventory.v1.UpdateControlSetting control_settings = 1;
   */
  controlSettings: UpdateControlSetting[] = [];

  /**
   * @generated from field: repeated inventory.v1.FactTypeToControlTypeSetting fact_type_to_control_type_settings = 2;
   */
  factTypeToControlTypeSettings: FactTypeToControlTypeSetting[] = [];

  constructor(data?: PartialMessage<UpdateControlSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_settings", kind: "message", T: UpdateControlSetting, repeated: true },
    { no: 2, name: "fact_type_to_control_type_settings", kind: "message", T: FactTypeToControlTypeSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSettingsRequest {
    return new UpdateControlSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSettingsRequest | PlainMessage<UpdateControlSettingsRequest> | undefined, b: UpdateControlSettingsRequest | PlainMessage<UpdateControlSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateControlSettingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlSetting
 */
export class UpdateControlSetting extends Message<UpdateControlSetting> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  /**
   * @generated from field: inventory.v1.InherentRiskSetting inherent_risk_setting = 2;
   */
  inherentRiskSetting = InherentRiskSetting.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateControlSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "inherent_risk_setting", kind: "enum", T: proto3.getEnumType(InherentRiskSetting) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlSetting {
    return new UpdateControlSetting().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlSetting | PlainMessage<UpdateControlSetting> | undefined, b: UpdateControlSetting | PlainMessage<UpdateControlSetting> | undefined): boolean {
    return proto3.util.equals(UpdateControlSetting, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlSettingsRequest
 */
export class ListControlSettingsRequest extends Message<ListControlSettingsRequest> {
  constructor(data?: PartialMessage<ListControlSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlSettingsRequest {
    return new ListControlSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlSettingsRequest | PlainMessage<ListControlSettingsRequest> | undefined, b: ListControlSettingsRequest | PlainMessage<ListControlSettingsRequest> | undefined): boolean {
    return proto3.util.equals(ListControlSettingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlSettingsResponse
 */
export class ListControlSettingsResponse extends Message<ListControlSettingsResponse> {
  /**
   * @generated from field: repeated inventory.v1.ControlSetting control_settings = 1;
   */
  controlSettings: ControlSetting[] = [];

  /**
   * @generated from field: repeated inventory.v1.FactTypeToControlTypeSetting fact_type_to_control_type_settings = 2;
   */
  factTypeToControlTypeSettings: FactTypeToControlTypeSetting[] = [];

  constructor(data?: PartialMessage<ListControlSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_settings", kind: "message", T: ControlSetting, repeated: true },
    { no: 2, name: "fact_type_to_control_type_settings", kind: "message", T: FactTypeToControlTypeSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlSettingsResponse {
    return new ListControlSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlSettingsResponse | PlainMessage<ListControlSettingsResponse> | undefined, b: ListControlSettingsResponse | PlainMessage<ListControlSettingsResponse> | undefined): boolean {
    return proto3.util.equals(ListControlSettingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlSetting
 */
export class ControlSetting extends Message<ControlSetting> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: string control_title = 3;
   */
  controlTitle = "";

  /**
   * @generated from field: inventory.v1.InherentRiskSetting inherent_risk_setting = 4;
   */
  inherentRiskSetting = InherentRiskSetting.UNSPECIFIED;

  /**
   * @generated from field: int32 order_priority = 5;
   */
  orderPriority = 0;

  constructor(data?: PartialMessage<ControlSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "control_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "inherent_risk_setting", kind: "enum", T: proto3.getEnumType(InherentRiskSetting) },
    { no: 5, name: "order_priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlSetting {
    return new ControlSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlSetting {
    return new ControlSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlSetting {
    return new ControlSetting().fromJsonString(jsonString, options);
  }

  static equals(a: ControlSetting | PlainMessage<ControlSetting> | undefined, b: ControlSetting | PlainMessage<ControlSetting> | undefined): boolean {
    return proto3.util.equals(ControlSetting, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlsRequest
 */
export class ListControlsRequest extends Message<ListControlsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListControlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlsRequest {
    return new ListControlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlsRequest | PlainMessage<ListControlsRequest> | undefined, b: ListControlsRequest | PlainMessage<ListControlsRequest> | undefined): boolean {
    return proto3.util.equals(ListControlsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListControlsResponse
 */
export class ListControlsResponse extends Message<ListControlsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Control controls = 1;
   */
  controls: Control[] = [];

  constructor(data?: PartialMessage<ListControlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListControlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "controls", kind: "message", T: Control, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListControlsResponse {
    return new ListControlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListControlsResponse | PlainMessage<ListControlsResponse> | undefined, b: ListControlsResponse | PlainMessage<ListControlsResponse> | undefined): boolean {
    return proto3.util.equals(ListControlsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlRequest
 */
export class GetControlRequest extends Message<GetControlRequest> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  constructor(data?: PartialMessage<GetControlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlRequest {
    return new GetControlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlRequest {
    return new GetControlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlRequest {
    return new GetControlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlRequest | PlainMessage<GetControlRequest> | undefined, b: GetControlRequest | PlainMessage<GetControlRequest> | undefined): boolean {
    return proto3.util.equals(GetControlRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlResponse
 */
export class GetControlResponse extends Message<GetControlResponse> {
  /**
   * @generated from field: inventory.v1.Control control = 1;
   */
  control?: Control;

  /**
   * @generated from field: string fact_title = 2;
   */
  factTitle = "";

  /**
   * @generated from field: repeated string finding_ids = 3;
   */
  findingIds: string[] = [];

  /**
   * @generated from field: string fact_markdown = 4;
   */
  factMarkdown = "";

  /**
   * @generated from field: inventory.v1.CompanySummary company = 6;
   */
  company?: CompanySummary;

  /**
   * @generated from field: repeated inventory.v1.Implication implications = 7;
   */
  implications: Implication[] = [];

  /**
   * @generated from field: string additional_fact_markdown = 8;
   */
  additionalFactMarkdown = "";

  /**
   * @generated from field: int32 additional_fact_count = 9;
   */
  additionalFactCount = 0;

  /**
   * @generated from field: inventory.v1.Documentation documentation = 10;
   */
  documentation?: Documentation;

  constructor(data?: PartialMessage<GetControlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control", kind: "message", T: Control },
    { no: 2, name: "fact_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finding_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "company", kind: "message", T: CompanySummary },
    { no: 7, name: "implications", kind: "enum", T: proto3.getEnumType(Implication), repeated: true },
    { no: 8, name: "additional_fact_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "additional_fact_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "documentation", kind: "message", T: Documentation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlResponse {
    return new GetControlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlResponse {
    return new GetControlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlResponse {
    return new GetControlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlResponse | PlainMessage<GetControlResponse> | undefined, b: GetControlResponse | PlainMessage<GetControlResponse> | undefined): boolean {
    return proto3.util.equals(GetControlResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlRequest
 */
export class UpdateControlRequest extends Message<UpdateControlRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional inventory.v1.ControlStatus status = 2;
   */
  status?: ControlStatus;

  /**
   * @generated from field: optional string comment = 3;
   */
  comment?: string;

  constructor(data?: PartialMessage<UpdateControlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(ControlStatus), opt: true },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlRequest {
    return new UpdateControlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlRequest {
    return new UpdateControlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlRequest {
    return new UpdateControlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlRequest | PlainMessage<UpdateControlRequest> | undefined, b: UpdateControlRequest | PlainMessage<UpdateControlRequest> | undefined): boolean {
    return proto3.util.equals(UpdateControlRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateControlResponse
 */
export class UpdateControlResponse extends Message<UpdateControlResponse> {
  constructor(data?: PartialMessage<UpdateControlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateControlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateControlResponse {
    return new UpdateControlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateControlResponse {
    return new UpdateControlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateControlResponse {
    return new UpdateControlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateControlResponse | PlainMessage<UpdateControlResponse> | undefined, b: UpdateControlResponse | PlainMessage<UpdateControlResponse> | undefined): boolean {
    return proto3.util.equals(UpdateControlResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlTimelineRequest
 */
export class GetControlTimelineRequest extends Message<GetControlTimelineRequest> {
  /**
   * @generated from field: string control_id = 1;
   */
  controlId = "";

  constructor(data?: PartialMessage<GetControlTimelineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlTimelineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlTimelineRequest {
    return new GetControlTimelineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlTimelineRequest {
    return new GetControlTimelineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlTimelineRequest {
    return new GetControlTimelineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlTimelineRequest | PlainMessage<GetControlTimelineRequest> | undefined, b: GetControlTimelineRequest | PlainMessage<GetControlTimelineRequest> | undefined): boolean {
    return proto3.util.equals(GetControlTimelineRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetControlTimelineResponse
 */
export class GetControlTimelineResponse extends Message<GetControlTimelineResponse> {
  /**
   * @generated from field: repeated inventory.v1.ControlTimelineItem items = 1;
   */
  items: ControlTimelineItem[] = [];

  constructor(data?: PartialMessage<GetControlTimelineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetControlTimelineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: ControlTimelineItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetControlTimelineResponse {
    return new GetControlTimelineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetControlTimelineResponse {
    return new GetControlTimelineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetControlTimelineResponse {
    return new GetControlTimelineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetControlTimelineResponse | PlainMessage<GetControlTimelineResponse> | undefined, b: GetControlTimelineResponse | PlainMessage<GetControlTimelineResponse> | undefined): boolean {
    return proto3.util.equals(GetControlTimelineResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlTimelineItem
 */
export class ControlTimelineItem extends Message<ControlTimelineItem> {
  /**
   * @generated from oneof inventory.v1.ControlTimelineItem.timeline_item
   */
  timelineItem: {
    /**
     * @generated from field: inventory.v1.ControlChange change = 1;
     */
    value: ControlChange;
    case: "change";
  } | {
    /**
     * @generated from field: inventory.v1.Comment comment = 2;
     */
    value: Comment;
    case: "comment";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ControlTimelineItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlTimelineItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "change", kind: "message", T: ControlChange, oneof: "timeline_item" },
    { no: 2, name: "comment", kind: "message", T: Comment, oneof: "timeline_item" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlTimelineItem {
    return new ControlTimelineItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlTimelineItem {
    return new ControlTimelineItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlTimelineItem {
    return new ControlTimelineItem().fromJsonString(jsonString, options);
  }

  static equals(a: ControlTimelineItem | PlainMessage<ControlTimelineItem> | undefined, b: ControlTimelineItem | PlainMessage<ControlTimelineItem> | undefined): boolean {
    return proto3.util.equals(ControlTimelineItem, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlChange
 */
export class ControlChange extends Message<ControlChange> {
  /**
   * @generated from field: google.protobuf.Timestamp time = 1;
   */
  time?: Timestamp;

  /**
   * @generated from field: inventory.v1.ControlStatus prev_status = 2;
   */
  prevStatus = ControlStatus.UNSPECIFIED;

  /**
   * @generated from field: inventory.v1.ControlStatus new_status = 3;
   */
  newStatus = ControlStatus.UNSPECIFIED;

  /**
   * @generated from field: optional inventory.v1.Comment comment = 6;
   */
  comment?: Comment;

  /**
   * @generated from field: string user_id = 7;
   */
  userId = "";

  constructor(data?: PartialMessage<ControlChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "prev_status", kind: "enum", T: proto3.getEnumType(ControlStatus) },
    { no: 3, name: "new_status", kind: "enum", T: proto3.getEnumType(ControlStatus) },
    { no: 6, name: "comment", kind: "message", T: Comment, opt: true },
    { no: 7, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlChange {
    return new ControlChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlChange {
    return new ControlChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlChange {
    return new ControlChange().fromJsonString(jsonString, options);
  }

  static equals(a: ControlChange | PlainMessage<ControlChange> | undefined, b: ControlChange | PlainMessage<ControlChange> | undefined): boolean {
    return proto3.util.equals(ControlChange, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlCountStat
 */
export class ControlCountStat extends Message<ControlCountStat> {
  /**
   * @generated from field: inventory.v1.ControlStatus status = 1;
   */
  status = ControlStatus.UNSPECIFIED;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<ControlCountStat>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlCountStat";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ControlStatus) },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlCountStat {
    return new ControlCountStat().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlCountStat {
    return new ControlCountStat().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlCountStat {
    return new ControlCountStat().fromJsonString(jsonString, options);
  }

  static equals(a: ControlCountStat | PlainMessage<ControlCountStat> | undefined, b: ControlCountStat | PlainMessage<ControlCountStat> | undefined): boolean {
    return proto3.util.equals(ControlCountStat, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetConifgurableFactTypesRequest
 */
export class GetConifgurableFactTypesRequest extends Message<GetConifgurableFactTypesRequest> {
  constructor(data?: PartialMessage<GetConifgurableFactTypesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetConifgurableFactTypesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConifgurableFactTypesRequest {
    return new GetConifgurableFactTypesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConifgurableFactTypesRequest {
    return new GetConifgurableFactTypesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConifgurableFactTypesRequest {
    return new GetConifgurableFactTypesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConifgurableFactTypesRequest | PlainMessage<GetConifgurableFactTypesRequest> | undefined, b: GetConifgurableFactTypesRequest | PlainMessage<GetConifgurableFactTypesRequest> | undefined): boolean {
    return proto3.util.equals(GetConifgurableFactTypesRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetConifgurableFactTypesResponse
 */
export class GetConifgurableFactTypesResponse extends Message<GetConifgurableFactTypesResponse> {
  /**
   * @generated from field: repeated inventory.v1.FactTypeToControlTypeOption fact_type_to_control_type_options = 1;
   */
  factTypeToControlTypeOptions: FactTypeToControlTypeOption[] = [];

  constructor(data?: PartialMessage<GetConifgurableFactTypesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetConifgurableFactTypesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact_type_to_control_type_options", kind: "message", T: FactTypeToControlTypeOption, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConifgurableFactTypesResponse {
    return new GetConifgurableFactTypesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConifgurableFactTypesResponse {
    return new GetConifgurableFactTypesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConifgurableFactTypesResponse {
    return new GetConifgurableFactTypesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConifgurableFactTypesResponse | PlainMessage<GetConifgurableFactTypesResponse> | undefined, b: GetConifgurableFactTypesResponse | PlainMessage<GetConifgurableFactTypesResponse> | undefined): boolean {
    return proto3.util.equals(GetConifgurableFactTypesResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.FactTypeToControlTypeOption
 */
export class FactTypeToControlTypeOption extends Message<FactTypeToControlTypeOption> {
  /**
   * @generated from field: string fact_type_id = 1;
   */
  factTypeId = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: repeated string exclude_from_control_ids = 3;
   */
  excludeFromControlIds: string[] = [];

  constructor(data?: PartialMessage<FactTypeToControlTypeOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FactTypeToControlTypeOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "exclude_from_control_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FactTypeToControlTypeOption {
    return new FactTypeToControlTypeOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FactTypeToControlTypeOption {
    return new FactTypeToControlTypeOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FactTypeToControlTypeOption {
    return new FactTypeToControlTypeOption().fromJsonString(jsonString, options);
  }

  static equals(a: FactTypeToControlTypeOption | PlainMessage<FactTypeToControlTypeOption> | undefined, b: FactTypeToControlTypeOption | PlainMessage<FactTypeToControlTypeOption> | undefined): boolean {
    return proto3.util.equals(FactTypeToControlTypeOption, a, b);
  }
}

/**
 * @generated from message inventory.v1.FactTypeToControlTypeSetting
 */
export class FactTypeToControlTypeSetting extends Message<FactTypeToControlTypeSetting> {
  /**
   * @generated from field: string fact_type_id = 1;
   */
  factTypeId = "";

  /**
   * @generated from field: string control_type_id = 2;
   */
  controlTypeId = "";

  constructor(data?: PartialMessage<FactTypeToControlTypeSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FactTypeToControlTypeSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fact_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "control_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FactTypeToControlTypeSetting {
    return new FactTypeToControlTypeSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FactTypeToControlTypeSetting {
    return new FactTypeToControlTypeSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FactTypeToControlTypeSetting {
    return new FactTypeToControlTypeSetting().fromJsonString(jsonString, options);
  }

  static equals(a: FactTypeToControlTypeSetting | PlainMessage<FactTypeToControlTypeSetting> | undefined, b: FactTypeToControlTypeSetting | PlainMessage<FactTypeToControlTypeSetting> | undefined): boolean {
    return proto3.util.equals(FactTypeToControlTypeSetting, a, b);
  }
}

