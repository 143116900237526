import { PartialMessage } from '@bufbuild/protobuf'
import { UseMutationResult, useMutation, useQuery } from '@tanstack/react-query'

import { Actor, NotificationType } from '@/gen/notifications/v1/model_pb'
import { listSlackChannels } from '@/gen/notifications/v1/notifications_service-NotificationsService_connectquery'
import { questionnaireActivity as questionnaireAssessorActivity } from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'
import type {
  QuestionnaireActivityRequest,
  QuestionnaireActivityResponse,
} from '@/gen/questionnaire/v1/model_pb'
import { questionnaireActivity as questionnaireRecipientActivity } from '@/gen/questionnaire/v1/questionnaire_service-QuestionnaireService_connectquery'

export const useListSlackChannels = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const queryState = useQuery(listSlackChannels.useQuery({ enabled }))
  return {
    ...queryState,
    data: queryState.data?.channels || [],
  }
}

type BaseNotificationProps = {
  roomId: string
  actorId: string
  type?: NotificationType
  threadId?: string
  magicLink?: string
}

type NotifyCommentAddedProps = BaseNotificationProps & {
  comment: string
  questionId?: string
}

const actorToMutationHook: Record<
  Actor,
  () => UseMutationResult<
    QuestionnaireActivityResponse,
    unknown,
    PartialMessage<QuestionnaireActivityRequest>
  >
> = {
  [Actor.ASSESSOR]: () => useQuestionnaireAssessorActivity(),
  [Actor.RECIPIENT]: () => useQuestionnaireRecipientActivity(),
  [Actor.UNSPECIFIED]: () => useQuestionnaireRecipientActivity(),
}

const createNotificationHook = <T extends BaseNotificationProps>(
  notificationType: NotificationType,
) => {
  return (actor: Actor) => {
    const { mutateAsync } = actorToMutationHook[actor]()

    return useMutation((props: T) =>
      mutateAsync({
        type: notificationType,
        roomId: props.roomId,
        actorId: props.actorId,
        threadId: props.threadId,
        magicLink: props.magicLink,
        ...(props as Partial<QuestionnaireActivityRequest>),
      }),
    )
  }
}

export const useQuestionnaireAssessorActivity = () => {
  return useMutation(questionnaireAssessorActivity.useMutation().mutationFn, {})
}

export const useQuestionnaireRecipientActivity = () => {
  return useMutation(questionnaireRecipientActivity.useMutation().mutationFn, {})
}

export const useNotifyUserEnteredQuestionnaire = createNotificationHook<BaseNotificationProps>(
  NotificationType.USER_ENTERED_QUESTIONNAIRE,
)

export const useNotifyQuestionUpdated = createNotificationHook<BaseNotificationProps>(
  NotificationType.QUESTION_UPDATED,
)

export const useNotifyThreadUnresolved = createNotificationHook<BaseNotificationProps>(
  NotificationType.THREAD_MARKED_UNRESOLVED,
)

export const useNotifyCommentAdded = createNotificationHook<NotifyCommentAddedProps>(
  NotificationType.COMMENT_ADDED,
)
