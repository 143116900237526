// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/model.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CompanySummary } from "../../inventory/v1/company_summary_pb.js";
import { NotificationType } from "../../notifications/v1/model_pb.js";

/**
 * @generated from enum questionnaire.v1.QuestionnaireStatus
 */
export enum QuestionnaireStatus {
  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_ONGOING = 1;
   */
  ONGOING = 1,

  /**
   * @generated from enum value: QUESTIONNAIRE_STATUS_COMPLETED = 2;
   */
  COMPLETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionnaireStatus)
proto3.util.setEnumType(QuestionnaireStatus, "questionnaire.v1.QuestionnaireStatus", [
  { no: 0, name: "QUESTIONNAIRE_STATUS_UNSPECIFIED" },
  { no: 1, name: "QUESTIONNAIRE_STATUS_ONGOING" },
  { no: 2, name: "QUESTIONNAIRE_STATUS_COMPLETED" },
]);

/**
 * @generated from message questionnaire.v1.QuestionnaireLink
 */
export class QuestionnaireLink extends Message<QuestionnaireLink> {
  /**
   * @generated from field: string recipient = 1;
   */
  recipient = "";

  /**
   * @generated from field: string magic_link = 2;
   */
  magicLink = "";

  constructor(data?: PartialMessage<QuestionnaireLink>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireLink";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipient", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireLink {
    return new QuestionnaireLink().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireLink | PlainMessage<QuestionnaireLink> | undefined, b: QuestionnaireLink | PlainMessage<QuestionnaireLink> | undefined): boolean {
    return proto3.util.equals(QuestionnaireLink, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.Questionnaire
 */
export class Questionnaire extends Message<Questionnaire> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp due_date = 4;
   */
  dueDate?: Timestamp;

  /**
   * @generated from field: string room_id = 5;
   */
  roomId = "";

  /**
   * @generated from field: questionnaire.v1.QuestionnaireStatus status = 7;
   */
  status = QuestionnaireStatus.UNSPECIFIED;

  /**
   * @generated from field: string company_id = 8;
   */
  companyId = "";

  /**
   * @generated from field: repeated string recipients = 9;
   */
  recipients: string[] = [];

  /**
   * @generated from field: optional inventory.v1.CompanySummary company_summary = 10;
   */
  companySummary?: CompanySummary;

  constructor(data?: PartialMessage<Questionnaire>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.Questionnaire";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "due_date", kind: "message", T: Timestamp },
    { no: 5, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(QuestionnaireStatus) },
    { no: 8, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "company_summary", kind: "message", T: CompanySummary, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Questionnaire {
    return new Questionnaire().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Questionnaire {
    return new Questionnaire().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Questionnaire {
    return new Questionnaire().fromJsonString(jsonString, options);
  }

  static equals(a: Questionnaire | PlainMessage<Questionnaire> | undefined, b: Questionnaire | PlainMessage<Questionnaire> | undefined): boolean {
    return proto3.util.equals(Questionnaire, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.QuestionnaireActivityRequest
 */
export class QuestionnaireActivityRequest extends Message<QuestionnaireActivityRequest> {
  /**
   * @generated from field: notifications.v1.NotificationType type = 1;
   */
  type = NotificationType.UNSPECIFIED;

  /**
   * @generated from field: string room_id = 2;
   */
  roomId = "";

  /**
   * @generated from field: string actor_id = 3;
   */
  actorId = "";

  /**
   * use for NOTIFICATION_TYPE_COMMENT_ADDED and NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED
   *
   * @generated from field: optional string thread_id = 4;
   */
  threadId?: string;

  /**
   * use for NOTIFICATION_TYPE_COMMENT_ADDED
   *
   * @generated from field: optional string comment = 5;
   */
  comment?: string;

  /**
   * @generated from field: optional string magic_link = 6;
   */
  magicLink?: string;

  /**
   * @generated from field: optional string question_id = 7;
   */
  questionId?: string;

  constructor(data?: PartialMessage<QuestionnaireActivityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireActivityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(NotificationType) },
    { no: 2, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "thread_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireActivityRequest {
    return new QuestionnaireActivityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireActivityRequest {
    return new QuestionnaireActivityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireActivityRequest {
    return new QuestionnaireActivityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireActivityRequest | PlainMessage<QuestionnaireActivityRequest> | undefined, b: QuestionnaireActivityRequest | PlainMessage<QuestionnaireActivityRequest> | undefined): boolean {
    return proto3.util.equals(QuestionnaireActivityRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.QuestionnaireActivityResponse
 */
export class QuestionnaireActivityResponse extends Message<QuestionnaireActivityResponse> {
  constructor(data?: PartialMessage<QuestionnaireActivityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.QuestionnaireActivityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionnaireActivityResponse {
    return new QuestionnaireActivityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionnaireActivityResponse {
    return new QuestionnaireActivityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionnaireActivityResponse {
    return new QuestionnaireActivityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionnaireActivityResponse | PlainMessage<QuestionnaireActivityResponse> | undefined, b: QuestionnaireActivityResponse | PlainMessage<QuestionnaireActivityResponse> | undefined): boolean {
    return proto3.util.equals(QuestionnaireActivityResponse, a, b);
  }
}

