// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file notifications/v1/model.proto (package notifications.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum notifications.v1.NotificationType
 */
export enum NotificationType {
  /**
   * @generated from enum value: NOTIFICATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTIONNAIRE_COMPLETED = 1;
   */
  QUESTIONNAIRE_COMPLETED = 1,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTIONNAIRE_DELETED = 2;
   */
  QUESTIONNAIRE_DELETED = 2,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_QUESTION_UPDATED = 3;
   */
  QUESTION_UPDATED = 3,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED = 4;
   */
  THREAD_MARKED_UNRESOLVED = 4,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_USER_ENTERED_QUESTIONNAIRE = 5;
   */
  USER_ENTERED_QUESTIONNAIRE = 5,

  /**
   * @generated from enum value: NOTIFICATION_TYPE_COMMENT_ADDED = 6;
   */
  COMMENT_ADDED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationType)
proto3.util.setEnumType(NotificationType, "notifications.v1.NotificationType", [
  { no: 0, name: "NOTIFICATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "NOTIFICATION_TYPE_QUESTIONNAIRE_COMPLETED" },
  { no: 2, name: "NOTIFICATION_TYPE_QUESTIONNAIRE_DELETED" },
  { no: 3, name: "NOTIFICATION_TYPE_QUESTION_UPDATED" },
  { no: 4, name: "NOTIFICATION_TYPE_THREAD_MARKED_UNRESOLVED" },
  { no: 5, name: "NOTIFICATION_TYPE_USER_ENTERED_QUESTIONNAIRE" },
  { no: 6, name: "NOTIFICATION_TYPE_COMMENT_ADDED" },
]);

/**
 * @generated from enum notifications.v1.Actor
 */
export enum Actor {
  /**
   * @generated from enum value: ACTOR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTOR_RECIPIENT = 1;
   */
  RECIPIENT = 1,

  /**
   * @generated from enum value: ACTOR_ASSESSOR = 2;
   */
  ASSESSOR = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Actor)
proto3.util.setEnumType(Actor, "notifications.v1.Actor", [
  { no: 0, name: "ACTOR_UNSPECIFIED" },
  { no: 1, name: "ACTOR_RECIPIENT" },
  { no: 2, name: "ACTOR_ASSESSOR" },
]);

