// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file questionnaire/v1/questionnaire_service.proto (package questionnaire.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CategoryMetadata } from "../../artifacts/store/v1/store_service_pb.js";
import { Questionnaire, QuestionnaireLink } from "./model_pb.js";

/**
 * @generated from message questionnaire.v1.TransferArtifactToAssessorRequest
 */
export class TransferArtifactToAssessorRequest extends Message<TransferArtifactToAssessorRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  /**
   * @generated from field: string artifact_id = 2;
   */
  artifactId = "";

  /**
   * @generated from field: string artifact_name = 3;
   */
  artifactName = "";

  /**
   * @generated from field: optional artifacts.store.v1.CategoryMetadata category_metadata = 4;
   */
  categoryMetadata?: CategoryMetadata;

  /**
   * @generated from field: optional string questionnaire_id_to_skip = 5;
   */
  questionnaireIdToSkip?: string;

  constructor(data?: PartialMessage<TransferArtifactToAssessorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.TransferArtifactToAssessorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "artifact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category_metadata", kind: "message", T: CategoryMetadata, opt: true },
    { no: 5, name: "questionnaire_id_to_skip", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransferArtifactToAssessorRequest {
    return new TransferArtifactToAssessorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransferArtifactToAssessorRequest {
    return new TransferArtifactToAssessorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransferArtifactToAssessorRequest {
    return new TransferArtifactToAssessorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TransferArtifactToAssessorRequest | PlainMessage<TransferArtifactToAssessorRequest> | undefined, b: TransferArtifactToAssessorRequest | PlainMessage<TransferArtifactToAssessorRequest> | undefined): boolean {
    return proto3.util.equals(TransferArtifactToAssessorRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.TransferArtifactToAssessorResponse
 */
export class TransferArtifactToAssessorResponse extends Message<TransferArtifactToAssessorResponse> {
  /**
   * @generated from field: string artifact_id = 1;
   */
  artifactId = "";

  constructor(data?: PartialMessage<TransferArtifactToAssessorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.TransferArtifactToAssessorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransferArtifactToAssessorResponse {
    return new TransferArtifactToAssessorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransferArtifactToAssessorResponse {
    return new TransferArtifactToAssessorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransferArtifactToAssessorResponse {
    return new TransferArtifactToAssessorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TransferArtifactToAssessorResponse | PlainMessage<TransferArtifactToAssessorResponse> | undefined, b: TransferArtifactToAssessorResponse | PlainMessage<TransferArtifactToAssessorResponse> | undefined): boolean {
    return proto3.util.equals(TransferArtifactToAssessorResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUserTokenRequest
 */
export class GetUserTokenRequest extends Message<GetUserTokenRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetUserTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUserTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserTokenRequest {
    return new GetUserTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserTokenRequest | PlainMessage<GetUserTokenRequest> | undefined, b: GetUserTokenRequest | PlainMessage<GetUserTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetUserTokenRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUserTokenResponse
 */
export class GetUserTokenResponse extends Message<GetUserTokenResponse> {
  /**
   * @generated from field: string user_token = 1;
   */
  userToken = "";

  constructor(data?: PartialMessage<GetUserTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUserTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserTokenResponse {
    return new GetUserTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserTokenResponse | PlainMessage<GetUserTokenResponse> | undefined, b: GetUserTokenResponse | PlainMessage<GetUserTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetUserTokenResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListRecipientQuestionnairesRequest
 */
export class ListRecipientQuestionnairesRequest extends Message<ListRecipientQuestionnairesRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<ListRecipientQuestionnairesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListRecipientQuestionnairesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecipientQuestionnairesRequest {
    return new ListRecipientQuestionnairesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecipientQuestionnairesRequest {
    return new ListRecipientQuestionnairesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecipientQuestionnairesRequest {
    return new ListRecipientQuestionnairesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecipientQuestionnairesRequest | PlainMessage<ListRecipientQuestionnairesRequest> | undefined, b: ListRecipientQuestionnairesRequest | PlainMessage<ListRecipientQuestionnairesRequest> | undefined): boolean {
    return proto3.util.equals(ListRecipientQuestionnairesRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.ListRecipientQuestionnairesResponse
 */
export class ListRecipientQuestionnairesResponse extends Message<ListRecipientQuestionnairesResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.Questionnaire questionnaires = 1;
   */
  questionnaires: Questionnaire[] = [];

  /**
   * @generated from field: string current_user_name = 2;
   */
  currentUserName = "";

  /**
   * @generated from field: string assessor_org_name = 3;
   */
  assessorOrgName = "";

  /**
   * @generated from field: repeated string recipients = 4;
   */
  recipients: string[] = [];

  /**
   * @generated from field: optional string contact_email = 5;
   */
  contactEmail?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp due_date = 6;
   */
  dueDate?: Timestamp;

  /**
   * @generated from field: optional bytes assessor_org_logo = 7;
   */
  assessorOrgLogo?: Uint8Array;

  constructor(data?: PartialMessage<ListRecipientQuestionnairesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.ListRecipientQuestionnairesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questionnaires", kind: "message", T: Questionnaire, repeated: true },
    { no: 2, name: "current_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "assessor_org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "contact_email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "due_date", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "assessor_org_logo", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRecipientQuestionnairesResponse {
    return new ListRecipientQuestionnairesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRecipientQuestionnairesResponse {
    return new ListRecipientQuestionnairesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRecipientQuestionnairesResponse {
    return new ListRecipientQuestionnairesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRecipientQuestionnairesResponse | PlainMessage<ListRecipientQuestionnairesResponse> | undefined, b: ListRecipientQuestionnairesResponse | PlainMessage<ListRecipientQuestionnairesResponse> | undefined): boolean {
    return proto3.util.equals(ListRecipientQuestionnairesResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUploadLinkRequest
 */
export class GetUploadLinkRequest extends Message<GetUploadLinkRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetUploadLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUploadLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUploadLinkRequest {
    return new GetUploadLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUploadLinkRequest | PlainMessage<GetUploadLinkRequest> | undefined, b: GetUploadLinkRequest | PlainMessage<GetUploadLinkRequest> | undefined): boolean {
    return proto3.util.equals(GetUploadLinkRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetUploadLinkResponse
 */
export class GetUploadLinkResponse extends Message<GetUploadLinkResponse> {
  /**
   * @generated from field: string upload_link = 1;
   */
  uploadLink = "";

  /**
   * @generated from field: string artifact_id = 2;
   */
  artifactId = "";

  constructor(data?: PartialMessage<GetUploadLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetUploadLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "artifact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUploadLinkResponse {
    return new GetUploadLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUploadLinkResponse | PlainMessage<GetUploadLinkResponse> | undefined, b: GetUploadLinkResponse | PlainMessage<GetUploadLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetUploadLinkResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.InviteRecipientRequest
 */
export class InviteRecipientRequest extends Message<InviteRecipientRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  /**
   * @generated from field: repeated string recipient_emails = 2;
   */
  recipientEmails: string[] = [];

  constructor(data?: PartialMessage<InviteRecipientRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.InviteRecipientRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recipient_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteRecipientRequest {
    return new InviteRecipientRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InviteRecipientRequest | PlainMessage<InviteRecipientRequest> | undefined, b: InviteRecipientRequest | PlainMessage<InviteRecipientRequest> | undefined): boolean {
    return proto3.util.equals(InviteRecipientRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.InviteRecipientResponse
 */
export class InviteRecipientResponse extends Message<InviteRecipientResponse> {
  /**
   * @generated from field: repeated questionnaire.v1.QuestionnaireLink links = 1;
   */
  links: QuestionnaireLink[] = [];

  constructor(data?: PartialMessage<InviteRecipientResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.InviteRecipientResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "links", kind: "message", T: QuestionnaireLink, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InviteRecipientResponse {
    return new InviteRecipientResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InviteRecipientResponse | PlainMessage<InviteRecipientResponse> | undefined, b: InviteRecipientResponse | PlainMessage<InviteRecipientResponse> | undefined): boolean {
    return proto3.util.equals(InviteRecipientResponse, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetParticipantsRequest
 */
export class GetParticipantsRequest extends Message<GetParticipantsRequest> {
  /**
   * @generated from field: string magic_link = 1;
   */
  magicLink = "";

  constructor(data?: PartialMessage<GetParticipantsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetParticipantsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "magic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetParticipantsRequest {
    return new GetParticipantsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetParticipantsRequest | PlainMessage<GetParticipantsRequest> | undefined, b: GetParticipantsRequest | PlainMessage<GetParticipantsRequest> | undefined): boolean {
    return proto3.util.equals(GetParticipantsRequest, a, b);
  }
}

/**
 * @generated from message questionnaire.v1.GetParticipantsResponse
 */
export class GetParticipantsResponse extends Message<GetParticipantsResponse> {
  /**
   * @generated from field: repeated string recipients = 1;
   */
  recipients: string[] = [];

  /**
   * @generated from field: repeated string assessors = 2;
   */
  assessors: string[] = [];

  constructor(data?: PartialMessage<GetParticipantsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "questionnaire.v1.GetParticipantsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipients", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "assessors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetParticipantsResponse {
    return new GetParticipantsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetParticipantsResponse | PlainMessage<GetParticipantsResponse> | undefined, b: GetParticipantsResponse | PlainMessage<GetParticipantsResponse> | undefined): boolean {
    return proto3.util.equals(GetParticipantsResponse, a, b);
  }
}

