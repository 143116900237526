import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export enum UrlParam {
  THIRD_PARTY_FILTER = 'thirdParty',
  THIRD_PARTY_ID = 'thirdPartyId',
  FINDING_ID = 'findingId',
  CONTROL_ID = 'controlId',
  READ_ONLY_CONTROL_ID = 'readOnlyControlId',
  READ_ONLY_FINDING_ID = 'readOnlyFindingId',
  SETTING_SECTION = 'settingSection',
  VIEW = 'view',
  CONTROL_VIEW = 'controlView',
  STEP = 'step',
  THIRD_PARTY_ACTIVITY_LOG_ID = 'thirdPartyActivityLogId',
  COMPANY_DRAWER_TAB = 'companyDrawerTab',
  QUESTIONNAIRE_ID = 'questionnaireId',
  HIGHLIGHTED_QUESTION_ID = 'highlightedQuestionId',
  RECIPIENT_QUESTIONNAIRE = 'recipientQuestionnaire',
}

interface UrlParamsHookResult {
  urlParams: Record<UrlParam, string | null>
  updateParam: (param: UrlParam, value: string) => void
  removeParam: (param: UrlParam) => void
  replaceParams: (params: { remove?: UrlParam[]; update?: [UrlParam, string][] }) => void
}

export const useUrlParams = (shouldCheckUnsavedChanges = false): UrlParamsHookResult => {
  const [searchParams, setSearchParams] = useSearchParams()

  const checkUnsavedChanges = useCallback(
    (newSection?: string) => {
      if (!shouldCheckUnsavedChanges) return true

      const event = new CustomEvent('checkUnsavedChanges', {
        detail: { newSection },
        cancelable: true,
      })
      return document.dispatchEvent(event)
    },
    [shouldCheckUnsavedChanges],
  )

  // Used instead remove and update to save browser history (so if user clicks back, the previous state is restored)
  const replaceParams = useCallback(
    ({ remove, update }: { remove?: UrlParam[]; update?: [UrlParam, string][] }) => {
      const newSection = update?.map(([_, value]) => value).join(',')
      if (!checkUnsavedChanges(newSection)) return

      setSearchParams((prev) => {
        const updatedParams = new URLSearchParams(prev)

        if (remove) {
          remove.forEach((param) => {
            updatedParams.delete(param)
          })
        }

        if (update) {
          update.forEach(([param, value]) => {
            updatedParams.set(param, value)
          })
        }

        return updatedParams
      })
    },
    [setSearchParams, checkUnsavedChanges],
  )

  const updateParam = useCallback(
    (param: UrlParam, value: string) => {
      if (!checkUnsavedChanges(value)) return
      const updatedParams = new URLSearchParams(window.location.search)
      updatedParams.set(param, value)
      setSearchParams(updatedParams.toString())
    },
    [setSearchParams, checkUnsavedChanges],
  )

  const removeParam = useCallback(
    (param: UrlParam) => {
      if (!checkUnsavedChanges()) return

      setSearchParams((prev) => {
        prev.delete(param)
        return prev
      })
    },
    [setSearchParams, checkUnsavedChanges],
  )

  const urlParams = Object.values(UrlParam).reduce(
    (acc, param) => {
      acc[param] = searchParams.get(param)
      return acc
    },
    {} as Record<UrlParam, string | null>,
  )

  return { urlParams, updateParam, removeParam, replaceParams }
}
